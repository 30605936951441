import axios, { AxiosPromise } from 'axios';
import { IActivityType } from '../../@types/model/masterData/activityType/activityType';
import { IBatchStatus } from '../../@types/model/masterData/batchStatus/batchStatus';
import { IBatchType } from '../../@types/model/masterData/batchType/batchType';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IDevice } from '../../@types/model/masterData/device/device';
import { IIngredient } from '../../@types/model/masterData/ingredient/ingredient';
import { ILocation } from '../../@types/model/masterData/location/location';
import { IMeasurementType } from '../../@types/model/masterData/measurementType/measurementType';
import { IReason } from '../../@types/model/masterData/reason/reason';
import { IRecipe } from '../../@types/model/masterData/recipe/recipe';
import { ISite } from '../../@types/model/masterData/site/site';
import { IUnitOfMeasure } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasure';

export default class DataHttpService {

    // SITE
    public static masterDataSiteGetList() : AxiosPromise<Array<ISite> | null> {
        return axios.get(`${API_URL}/MasterData/Site/GetList`);
    }

    public static siteSave = (site : ISite) : AxiosPromise<ISite | null> => {
        return axios.post(`${API_URL}/MasterData/Site/Save`, site);
    };

    public static siteDelete = (siteId : number) : AxiosPromise<ISite> => {
        return axios.delete(`${API_URL}/MasterData/Site/Delete`, {
            params: { siteId },
        });
    };

    // DEPARTMENT
    public static masterDataDepartmentGetList(siteId : number) : AxiosPromise<Array<IDepartment> | null> {
        return axios.get(`${API_URL}/MasterData/Department/GetList`, {
            params: {
                siteId,
            },
        });
    }

    public static departmentSave = (department : IDepartment) : AxiosPromise<IDepartment | null> => {
        return axios.post(`${API_URL}/MasterData/Department/Save`, department);
    };

    public static departmentDelete = (departmentId : number) : AxiosPromise<IDepartment> => {
        return axios.delete(`${API_URL}/MasterData/Department/Delete`, {
            params: { departmentId },
        });
    };

    // LOCATION

    public static masterDataLocationGetList(departmentId : number) : AxiosPromise<Array<ILocation> | null> {
        return axios.get(`${API_URL}/MasterData/Location/GetList`, {
            params: {
                departmentId,
            },
        });
    }

    public static masterDataLocationGetListBySite(siteId : number) : AxiosPromise<Array<ILocation> | null> {
        return axios.get(`${API_URL}/MasterData/Location/GetListBySite`, {
            params: {
                siteId,
            },
        });
    }

    public static locationSave = (location : ILocation) : AxiosPromise<ILocation | null> => {
        return axios.post(`${API_URL}/MasterData/Location/Save`, location);
    };

    public static locationDelete = (locationId : number) : AxiosPromise<ILocation> => {
        return axios.delete(`${API_URL}/MasterData/Location/Delete`, {
            params: { locationId },
        });
    };

    // DEVICE

    public static masterDataDeviceGetList(locationId : number) : AxiosPromise<Array<IDevice> | null> {
        return axios.get(`${API_URL}/MasterData/Device/GetList`, {
            params: {
                locationId,
            },
        });
    }

    public static masterDataDeviceGetListBySiteId(siteId : number) : AxiosPromise<Array<IDevice> | null> {
        return axios.get(`${API_URL}/MasterData/Device/GetListBySiteId`, {
            params: {
                siteId,
            },
        });
    }

    public static deviceSave = (device : IDevice) : AxiosPromise<IDevice | null> => {
        return axios.post(`${API_URL}/MasterData/Device/Save`, device);
    };

    public static deviceDelete = (deviceId : number) : AxiosPromise<IDevice> => {
        return axios.delete(`${API_URL}/MasterData/Device/Delete`, {
            params: { deviceId },
        });
    };

    // ACTIVITY TYPE

    public static masterDataActivityTypeGetList() : AxiosPromise<Array<IActivityType> | null> {
        return axios.get(`${API_URL}/MasterData/ActivityType/GetList`);
    }

    public static activityTypeSave = (activityType : IActivityType) : AxiosPromise<IActivityType | null> => {
        return axios.post(`${API_URL}/MasterData/ActivityType/Save`, activityType);
    };

    public static activityTypeDelete = (activityTypeId : number) : AxiosPromise<IActivityType> => {
        return axios.delete(`${API_URL}/MasterData/ActivityType/Delete`, {
            params: { activityTypeId },
        });
    };

    // BATCH TYPE

    public static masterDataBatchTypeGetList() : AxiosPromise<Array<IBatchType> | null> {
        return axios.get(`${API_URL}/MasterData/BatchType/GetList`);
    }

    public static batchTypeSave = (batchType : IBatchType) : AxiosPromise<IBatchType | null> => {
        return axios.post(`${API_URL}/MasterData/BatchType/Save`, batchType);
    };

    public static batchTypeDelete = (batchTypeId : number) : AxiosPromise<IBatchType> => {
        return axios.delete(`${API_URL}/MasterData/BatchType/Delete`, {
            params: { batchTypeId },
        });
    };

    // BATCH STATUS

    public static masterDataBatchStatusGetList() : AxiosPromise<Array<IBatchStatus> | null> {
        return axios.get(`${API_URL}/MasterData/BatchStatus/GetList`);
    }

    public static batchStatusSave = (batchStatus : IBatchStatus) : AxiosPromise<IBatchStatus | null> => {
        return axios.post(`${API_URL}/MasterData/BatchStatus/Save`, batchStatus);
    };

    public static batchStatusDelete = (batchStatusId : number) : AxiosPromise<IBatchStatus> => {
        return axios.delete(`${API_URL}/MasterData/BatchStatus/Delete`, {
            params: { batchStatusId },
        });
    };

    // MEASUREMENT TYPE

    public static masterDataMeasurementTypeGetList() : AxiosPromise<Array<IMeasurementType> | null> {
        return axios.get(`${API_URL}/MasterData/MeasurementType/GetList`);
    }

    public static measurementTypeSave = (measurementType : IMeasurementType) : AxiosPromise<IMeasurementType | null> => {
        return axios.post(`${API_URL}/MasterData/MeasurementType/Save`, measurementType);
    };

    public static measurementTypeDelete = (measurementTypeId : number) : AxiosPromise<IMeasurementType> => {
        return axios.delete(`${API_URL}/MasterData/MeasurementType/Delete`, {
            params: { measurementTypeId },
        });
    };

    // REASON

    public static masterDataReasonGetList() : AxiosPromise<Array<IReason> | null> {
        return axios.get(`${API_URL}/MasterData/Reason/GetList`);
    }

    public static reasonSave = (reason : IReason) : AxiosPromise<IReason | null> => {
        return axios.post(`${API_URL}/MasterData/Reason/Save`, reason);
    };

    public static reasonDelete = (reasonId : number) : AxiosPromise<IReason> => {
        return axios.delete(`${API_URL}/MasterData/Reason/Delete`, {
            params: { reasonId },
        });
    };

    // RECIPE

    public static masterDataRecipeGetList() : AxiosPromise<Array<IRecipe> | null> {
        return axios.get(`${API_URL}/MasterData/Recipe/GetList`);
    }

    public static recipeSave = (recipe : IRecipe) : AxiosPromise<IRecipe | null> => {
        return axios.post(`${API_URL}/MasterData/Recipe/Save`, recipe);
    };

    public static recipeDelete = (recipeId : number) : AxiosPromise<IRecipe> => {
        return axios.delete(`${API_URL}/MasterData/Recipe/Delete`, {
            params: { recipeId },
        });
    };

    // UNIT OF MEASURE

    public static masterDataUnitOfMeasureGetList() : AxiosPromise<Array<IUnitOfMeasure> | null> {
        return axios.get(`${API_URL}/MasterData/UnitOfMeasure/GetList`);
    }

    public static unitOfMeasureSave = (unitOfMeasure : IUnitOfMeasure) : AxiosPromise<IUnitOfMeasure | null> => {
        return axios.post(`${API_URL}/MasterData/UnitOfMeasure/Save`, unitOfMeasure);
    };

    public static unitOfMeasureDelete = (unitOfMeasureId : number) : AxiosPromise<IUnitOfMeasure> => {
        return axios.delete(`${API_URL}/MasterData/UnitOfMeasure/Delete`, {
            params: { unitOfMeasureId },
        });
    };

    // INGREDIENTS

    public static masterDataIngredientGetList() : AxiosPromise<Array<IIngredient> | null> {
        return axios.get(`${API_URL}/MasterData/Ingredient/GetList`);
    }

    public static ingredientSave = (ingredient : IIngredient) : AxiosPromise<IIngredient | null> => {
        return axios.post(`${API_URL}/MasterData/Ingredient/Save`, ingredient);
    };

    public static ingredientDelete = (ingredientId : number) : AxiosPromise<IIngredient> => {
        return axios.delete(`${API_URL}/MasterData/Ingredient/Delete`, {
            params: { ingredientId },
        });
    };
}
