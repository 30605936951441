import { createReducer } from '@reduxjs/toolkit';
import BatchActions from './actions';
import { IBatch } from '../../@types/model/batch/batch';
import { IBatchIngredientBridge } from '../../@types/model/batch/bridges/batchIngredientBridge';

export interface IBatchState {
    isLoading : boolean;
    isBatchBeingEdited : boolean;
    batchData : Array<IBatch> | null;
    selectedBatch : IBatch | null;
    selectedBatchIngredientBridge : IBatchIngredientBridge | null;
    isBatchCreateRecipePopupOpen : boolean;
    isBatchRecipeEditFormSubmitting : boolean;
    loadBatchReports : boolean;
}

const initialState = {
    isLoading: false,
    isBatchBeingEdited: false,
    batchData: null,
    selectedBatch: null,
    selectedBatchIngredientBridge: null,
    isBatchCreateRecipePopupOpen: false,
    isBatchRecipeEditFormSubmitting: false,
    loadBatchReports: false,
};

const batchReducer = createReducer<IBatchState>(initialState, (builder) => {
    builder.addCase(BatchActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(BatchActions.setIsBatchBeingEdited, (state, action) => {
        state.isBatchBeingEdited = action.payload;
    });
    builder.addCase(BatchActions.setBatchData, (state, action) => {
        state.batchData = action.payload;
    });
    builder.addCase(BatchActions.setSelectedBatch, (state, action) => {
        state.selectedBatch = action.payload;
    });
    builder.addCase(BatchActions.setSelectedBatchIngredientBridge, (state, action) => {
        state.selectedBatchIngredientBridge = action.payload;
    });
    builder.addCase(BatchActions.setBatchCreateRecipePopupOpen, (state, action) => {
        state.isBatchCreateRecipePopupOpen = action.payload;
    });
    builder.addCase(BatchActions.setIsBatchRecipeEditFormSubmitting, (state, action) => {
        state.isBatchRecipeEditFormSubmitting = action.payload;
    });
    builder.addCase(BatchActions.setLoadBatchReports, (state, action) => {
        state.loadBatchReports = action.payload;
    });
    builder.addCase(BatchActions.reset, () => {
        return initialState;
    });
});

export default batchReducer;
