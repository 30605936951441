import { IActivity } from '../../@types/model/activity/activity';
import ActivityActions from './actions';
import { createReducer } from '@reduxjs/toolkit';

export interface IActivityState {
    isLoading : boolean;
    activityData : Array<IActivity> | null;
    selectedActivity : IActivity | null;
}

const initialState = {
    isLoading: false,
    activityData: null,
    selectedActivity: null,
};

const activityReducer = createReducer<IActivityState>(initialState, (builder) => {
    builder.addCase(ActivityActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(ActivityActions.setActivityData, (state, action) => {
        state.activityData = action.payload;
    });
    builder.addCase(ActivityActions.setSelectedActivity, (state, action) => {
        state.selectedActivity = action.payload;
    });
    builder.addCase(ActivityActions.reset, () => {
        return initialState;
    });
});

export default activityReducer;
