import { combineReducers } from 'redux';
import { createHashHistory } from 'history';
import auth from './auth/reducer';
import general from './general/reducer';
import masterData from './masterData/reducer';
import batch from './batch/reducer';
import activity from './activity/reducer';
import measurement from './measurement/reducer';
import right from './right/reducer';
import freshService from './freshService/reducer';
import log from './logging/reducer';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';

export const history = createHashHistory();

export const createRootReducer = combineReducers({
    auth,
    masterData,
    general,
    batch,
    activity,
    measurement,
    right,
    freshService,
    log
});

export const store = configureStore({
    reducer: createRootReducer,
    middleware: getDefaultMiddleware({
        serializableCheck: false,
        // immutableCheck: false
    }),
});

export default store;
export const dispatch = store.dispatch;
export const getState = store.getState;
