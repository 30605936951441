import { getState } from '..';
import RightActions from './actions';
import lodash from 'lodash';
import UserHttpService from '../../service/http/userHttpService';
import RightHttpService from '../../service/http/rightsHttpService';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from '../../@types/redux';
import GeneralThunks from '../general/thunks';
import { IRight } from '../../@types/model/auth/right/right';
import { IRightsUpsert } from '../../@types/model/auth/right/rightsUpsert';
import { IUser } from '../../@types/model/auth/user/user';
import { IUserUpsert } from '../../@types/model/auth/user/userUpsert';
import { ArrayHelper } from '@zz2/zz2-ui';

export default class RightThunks {
    /**
     * Retrieves the list of users from the API, updating the redux state once complete. Performs no
     * action if no users have been retrieved.
     *
     * @returns {Array<IUser> | null}
     */
    public static getUserList = createAsyncThunk<
    Array<IUser> | null,
    undefined,
    ThunkApi>(
        'RIGHTS_LOAD_USERS',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));
    
                const res = await UserHttpService.userGetList();
    
                thunkApi.dispatch(RightActions.setUserData(res.data));
    
                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading users.', e }));
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        }
    );

    /**
     * Retrieves the list of rights from the API, updating the redux state once complete. Performs no
     * action if no rights have been retrieved.
     *
     * @returns {Array<IRight> | null}
     */
    public static getRightList = createAsyncThunk<
    Array<IRight> | null,
    undefined,
    ThunkApi>(
        'RIGHTS_LOAD_RIGHTS',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));

                const res = await RightHttpService.rightGetList();

                if (res.data) {
                    thunkApi.dispatch(RightActions.setRightData(res.data));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading rights.', e }));
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /****************************** UPSERT *********************************/

    /**
     * Inserts a user, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IUser} data
     * @returns {IUser | null}
     */
    public static upsertUser = createAsyncThunk<
    IUser | null,
    IUserUpsert,
    ThunkApi>(
        'RIGHTS_UPSERT_USER',
        async (upsert, thunkApi) => {
            try {
                const state = getState();
                const userList = lodash.map(state.right.userData, x => x);

                thunkApi.dispatch(RightActions.setIsLoading(true));

                // insert entry
                const res = await UserHttpService.userUpsert(upsert);
                
                if (res.data) {
                    const newList = ArrayHelper.upsertElement(userList, res.data, a => a.id === res.data?.id) || [];
                    thunkApi.dispatch(RightActions.setUserData(newList));

                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the user.', e }));
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        }
    );

    /**
     * Inserts a right, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IRightsUpsert} data
     * @returns {IRight | null}
     */
    public static upsertRight = createAsyncThunk<
    IRight | null,
    IRightsUpsert,
    ThunkApi>(
        'RIGHT_UPSERT_RIGHT',
        async (upsert, thunkApi) => {
            try {
                const state = getState();
                const rightList = lodash.map(state.right.rightData, x => x);
    
                thunkApi.dispatch(RightActions.setIsLoading(true));
    
                // insert entry
                const res = await RightHttpService.rightUpsert(upsert);

                if (res.data) {
                    const newList = ArrayHelper.upsertElement(rightList, res.data, a => a.id === res.data!.id) || [];
                    thunkApi.dispatch(RightActions.setRightData(newList));

                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the right.', e }));
                return null;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        }
    );

    /****************************** DELETE *********************************/

    /**
     * Deletes a user, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} userId
     * @returns {boolean}
     */
    public static deleteUser = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'RIGHT_DELETE_USER',
        async (userId, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));

                await UserHttpService.userDelete(userId);

                const users : Array<IUser> = thunkApi.getState().right.userData;
                const deletedUser = users.find(x => x.id === userId);
                const index = users.findIndex(x => x.id === userId);

                if (deletedUser && index >= 0) {
                    const updatedUser : IUser = {
                        ...deletedUser,
                        isActive: false,
                    };
                    const newList = ArrayHelper.setElement(users, index, updatedUser);
                    thunkApi.dispatch(RightActions.setUserData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting user.', e }));
                return false;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        }
    );

    /**
     * Deletes a right, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} rightId
     * @returns {boolean}
     */
    public static deleteRight = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'RIGHT_DELETE_RIGHT',
        async (rightId, thunkApi) => {
            try {
                thunkApi.dispatch(RightActions.setIsLoading(true));

                await RightHttpService.rightDelete(rightId);

                const rights : Array<IRight> = thunkApi.getState().right.rightData;
                const deletedRight = rights.find(x => x.id === rightId);
                const index = rights.findIndex(x => x.id === rightId);

                if (deletedRight && index >= 0) {
                    const updatedRight : IRight = {
                        ...deletedRight,
                        isActive: false,
                    };
                    const newList = ArrayHelper.setElement(rights, index, updatedRight);
                    thunkApi.dispatch(RightActions.setRightData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting right.', e }));
                return false;
            } finally {
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        }
    );
}
