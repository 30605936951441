import { IOptionType } from '@zz2/zz2-ui';
import { IDepartment } from './masterData/department/department';
import { ISite } from './masterData/site/site';
import { ILocation } from './masterData/location/location';
import { IActivityType } from './masterData/activityType/activityType';
import { IMeasurementType } from './masterData/measurementType/measurementType';
import { IUnitOfMeasure } from './masterData/unitOfMeasure/unitOfMeasure';
import { IReason } from './masterData/reason/reason';
import { IBatchLocationBridge } from './batch/bridges/batchLocationBridge';
import { IBatch } from './batch/batch';
import { IBatchType } from './masterData/batchType/batchType';
import { IBatchStatus } from './masterData/batchStatus/batchStatus';
import { IRecipe } from './masterData/recipe/recipe';
import { IRight } from './auth/right/right';
import { IIngredient } from './masterData/ingredient/ingredient';

export class OptionType {

    /*================================================================================================================
     *                                                  Master Data
     * ==============================================================================================================*/

    public static fromDepartment = (department : IDepartment) : IOptionType => {
        return {
            label: department.name,
            value: department.id,
        };
    };

    public static fromSite = (site : ISite) : IOptionType => {
        return {
            label: site.name,
            value: site.id,
        };
    };

    public static fromLocation = (location : ILocation) : IOptionType => {
        return {
            label: `${location.code} - ${location.name}`,
            value: location.id,
        };
    };

    public static fromBatchLocationBridge = (batchLocationBridge : IBatchLocationBridge) : IOptionType => {
        return {
            label: `${batchLocationBridge.location.code} - ${batchLocationBridge.location.name}`,
            value: batchLocationBridge.locationId,
        };
    };

    public static fromActivityType = (activityType : IActivityType) : IOptionType => {
        return {
            label: `${activityType.code} - ${activityType.name}`,
            value: activityType.id,
        };
    };

    public static fromMeasurementType = (measurementType : IMeasurementType) : IOptionType => {
        return {
            label: measurementType.name,
            value: measurementType.id,
        };
    };

    public static fromUnitOfMeasure = (unitOfMeasure : IUnitOfMeasure) : IOptionType => {
        return {
            label: unitOfMeasure.name,
            value: unitOfMeasure.id,
        };
    };

    public static fromBatch = (batch : IBatch) : IOptionType => {
        return {
            label: batch.name,
            value: batch.id,
        };
    };

    public static fromBatchFullName = (batch : IBatch) : IOptionType => {
        return {
            label: `${batch.code} - ${batch.name}`,
            value: batch.id,
        };
    };

    public static fromReason = (reason : IReason) : IOptionType => {
        return {
            label: reason.description,
            value: reason.id,
        };
    };

    public static fromBatchType = (batchType : IBatchType) : IOptionType => {
        return {
            label: `${batchType.name} - ${batchType.code}`,
            value: batchType.id,
        };
    };

    public static fromBatchStatus = (batchStatus : IBatchStatus) : IOptionType => {
        return {
            label: batchStatus.code,
            value: batchStatus.id,
        };
    };

    public static fromRecipe = (recipe : IRecipe) : IOptionType => {
        return {
            label: `${recipe.name} - ${recipe.code}`,
            value: recipe.id,
        };  
    };

    public static fromIngredient = (ingredient : IIngredient) : IOptionType => {
        return {
            label: ingredient.code,
            value: ingredient.id,
        };
    };

    /*================================================================================================================
     *                                                  Auth
     * ==============================================================================================================*/

    public static fromRight = (right : IRight) : IOptionType => {
        return {
            label: `(${right.code}) ${right.name}`,
            value: right.id,
        };
    };
}