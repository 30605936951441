import React, { useEffect, useMemo, useState } from 'react';
import { IUser } from '../../@types/model/auth/user/user';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import { useLocation } from 'react-router';
import GeneralActions from '../../store/general/actions';
import AuthThunks from '../../store/auth/thunk';
import { IRight } from '../../@types/model/auth/right/right';
import * as localStorageService from '../../service/localStorageService';
import { NavBar, NavDrawer, ProjectDialog, lowercase } from '@zz2/zz2-ui';
import { VERSION } from '../../version';
import { IOptionType } from '@zz2/zz2-ui';
import SideMenuItem from '../component/menu/SideMenuItem';
import { Link } from 'react-router-dom';
import { Icon, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import lodash from 'lodash';
import UserHelper from '../../service/helper/userHelper';
import UserSettings from './UserSettings';
import Routes from './Routes';
import { IFreshServiceTicketFormValues } from '../../@types/model/freshService/freshServiceTicketFormValues';
import FreshServiceTicketModelHelper from '../../@types/model/freshService/freshServiceTicketModelHelper';
import RightThunks from '../../store/right/thunks';
import FreshServiceThunks from '../../store/freshService/thunks';
import TicketCreationPopup from '../component/dialog/TicketCreationPopup';

const iconsLocation = `${ASSET_BASE}/assets/icons`;

const NavFrame = () : React.ReactElement => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const freshServiceIsLoading = useAppSelector(x => x.freshService.isLoading);
    const isUsersLoading = useAppSelector<boolean>(x => x.right.isLoading);
    const users = useAppSelector<Array<IUser>>(x => x.right.userData);

    const currentUser = useAppSelector<IUser | undefined>(x => x.auth.session?.user);
    const isNavDrawerOpen = useAppSelector(x => x.general.isNavDrawerOpen);

    const userSelectedSite : IOptionType | null = localStorageService.getUserSelectedSiteLocalStorage();

    const [isUserSettingsDialogOpen, setIsUserSettingsDialogOpen] = useState<boolean>(false);
    const [isSupportTicketDialogOpen, setIsSupportTicketDialogOpen] = useState<boolean>(false);

    /*================================================================================================================
     *                                              Effects
     * ==============================================================================================================*/

    useEffect(() => {    
        loadData();
    }, []);

    useEffect(() => {
        if (!isSupportTicketDialogOpen) return;

        loadUserList();
    }, [isSupportTicketDialogOpen]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const loadData = () : void => {
        dispatch(RightThunks.getUserList());
        dispatch(RightThunks.getRightList());
        if (currentUser) {
            if (!userSelectedSite) {
                setIsUserSettingsDialogOpen(true);
            }
        }
    };

    const onTicketSubmit = async (values : IFreshServiceTicketFormValues) : Promise<void> => {
        const upsertValues = FreshServiceTicketModelHelper.createUpsert(values, users);
        const res = await dispatch(FreshServiceThunks.createFreshServiceTicket(upsertValues)).unwrap();
        
        if (res) {
            closeSupportTicketDialog();
        }
    };

    const loadUserList = () : void => {
        dispatch(RightThunks.getUserList());
    };

    const signOut = () : void => {
        dispatch(AuthThunks.logOut());
        dispatch(GeneralActions.setNavDrawer(false));
    };

    /*================================================================================================================
     *                                          Handler Methods - NavBar & Drawer
     * ==============================================================================================================*/

    const openDrawer = () : void => {
        dispatch(GeneralActions.setNavDrawer(true));
    };

    const closeDrawer = () : void => {
        dispatch(GeneralActions.setNavDrawer(false));
    };

    const openUserSettings = () : void => {
        setIsUserSettingsDialogOpen(true);
    };

    const closeUserSettings = () : void => {
        setIsUserSettingsDialogOpen(false);
    };

    const updateVersion = () : void => {
        console.log('update version script to be added here.');
    };

    const openSupportTicketDialog = () : void => {
        closeDrawer();
        setIsSupportTicketDialogOpen(true);
    };

    const closeSupportTicketDialog = () : void => {
        setIsSupportTicketDialogOpen(false);
    };

    const isParentOfCurrentPage = (parentId : number, rights ?: Array<IRight>, pathname ?: string) : boolean => {
        const currentPageRight = rights?.find(x => x.url === pathname);
        const parentRight = rights?.find(x => x.id === parentId);
    
        if (currentPageRight?.parentId) {
            return (currentPageRight.parentId === parentId) || (!!parentRight?.parentId && isParentOfCurrentPage(parentRight.parentId, rights, pathname));
        }
        return false;
    };

    const getEnvironment = () : string => {
        if (ENV_NAME === 'production') {
            return 'ZZ2 COMPOST';
        } else if (ENV_NAME === 'qa') {
            return 'ZZ2 COMPOST QA';
        } else if (ENV_NAME === 'dev') {
            return 'ZZ2 COMPOST DEV';
        } else {
            return 'ZZ2 COMPOST ENV NOT FOUND';
        }
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const getInitialSupportTicketFormValues = useMemo<IFreshServiceTicketFormValues>(() => {
        const regex = new RegExp('/', 'g');
        const formattedLocation = (`Compost ${ENV_NAME.toUpperCase()} Website` + (location.pathname === '/' ? '/Home' : location.pathname) + '/' + VERSION.version).replace(regex, ' - ');

        return FreshServiceTicketModelHelper.createFormValues(formattedLocation, currentUser);
    }, [currentUser, location.pathname]);

    const siteHeading = useMemo<string>(() => {
        return UserHelper.getUserSiteHeading(userSelectedSite);
    }, [userSelectedSite]);

    const rights = useMemo<Array<IRight>>(() => {
        return currentUser?.rights ?? [];
    }, [currentUser]);

    const userCurrentRight = useMemo<IRight | undefined>(() => {
        return currentUser?.rights.filter(x => x.isActive)?.find(x => x.url === location.pathname);
    }, [currentUser, location.pathname]);

    const currentUserRights = useMemo<Array<IRight>>(() => {
        return currentUser?.rights ?? [];
    }, [currentUser]);

    const breadcrumbs = useMemo<Array<{ name : string; url : string }>>(() => {
        const breadcrumbList : Array<{ name : string; url : string }> = [];

        if (userCurrentRight) {
            let currentRight : IRight | undefined = userCurrentRight;

            do {
                breadcrumbList.push({ name: currentRight.name, url: currentRight.url });
                currentRight = rights.find(x => x.id === currentRight?.breadcrumbParentId);
            } while (currentRight);
        }
        return breadcrumbList.reverse();
    }, [currentUser, location, rights, userCurrentRight]);

    const userSettings = useMemo<React.ReactElement>(() => {
        return <UserSettings 
            isOpen={isUserSettingsDialogOpen}
            currentUser={currentUser}
            setIsUserDialogOpenCallback={(state : boolean) : void => setIsUserSettingsDialogOpen(state)}
            onClose={closeUserSettings}
            signOut={signOut}
        />;
    }, [isUserSettingsDialogOpen]);

    const supportTicketDialog = useMemo<React.ReactElement>(() => {
        return (
            <ProjectDialog
                title={'ZZ2FreshService Support Ticket'}
                isLoadingCircular={freshServiceIsLoading || isUsersLoading}
                isOpen={isSupportTicketDialogOpen}
                fullWidth
                maxWidth={'xs'}
                onClose={closeSupportTicketDialog}>
                <TicketCreationPopup initialValues={getInitialSupportTicketFormValues} onSubmit={onTicketSubmit} onClose={closeSupportTicketDialog}/>
            </ProjectDialog >
        );
    }, [isSupportTicketDialogOpen]);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    const getIcons = (name : string) : React.ReactElement => {
        if (name === 'activity') {
            return (<Icon className={'fs30 cpd'}>assignment</Icon>);
        } else if (name === 'device') {
            return (<Icon className={'fs30 cpd'}>phonelink_setup</Icon>);
        } else {
            return (<img className={'h30 w30'} src={`${iconsLocation}/${lowercase(name).replace(/ /g, '_')}.svg`}/>);
        }
    };

    const renderListSection = (right : IRight, children : Array<IRight>, rights ?: Array<IRight>, pathname ?: string) : React.ReactElement => {
        return (
            <SideMenuItem
                className={'pt10'}
                paddingLeft={0}
                icon={getIcons(lowercase(right.name))}
                title={right.name}
                key={'section_' + right.id}
                boldTitle={isParentOfCurrentPage(right.id, rights, pathname)}>
                {children.map(x => renderListItem(x, false, pathname))}
            </SideMenuItem>
        );
    };

    const renderListItem = (right : IRight, hasIcon : boolean, pathname ?: string) : React.ReactElement => {
        return (
            <Link key={`link ${right.url}`} to={right.url} onClick={closeDrawer} className={'tdus cb'}>
                <ListItem button key={right.guid}>
                    {
                        hasIcon ?
                            <ListItemIcon key={`icon ${right.url}`}>
                                {<img className={'h30 w30'} src={`${iconsLocation}/${lowercase(right.name).replace(/ /g, '_')}.svg`} />}
                            </ListItemIcon>
                            :
                            <div className={'h30 w30'} />
                    }
                    <ListItemText disableTypography className={`${pathname === right.url ? 'fw550' : ''} pl0`} inset primary={right.name} />
                </ListItem>
            </Link>
        );
    };
    
    const sections = useMemo<Array<React.ReactElement>>(() => {
        const uniqueRights = lodash.uniqBy(currentUserRights, x => x.id);
    
        return lodash.chain(uniqueRights)
            .filter(x => x.isOnNavDrawer && x.isActive && !x.parentId)
            .filter(x => !!x.sectionOrder)
            .sortBy(x => x.sectionOrder)
            .map((x) => {
                return {
                    section: x,
                    children: lodash.chain(uniqueRights)
                        .filter(y => y.isOnNavDrawer && y.isActive && y.parentId === x.id)
                        .filter(y => !!y.pageOrder)
                        .sortBy(y => y.sectionOrder).value(),
                };
            }).map((x) => {
                return x.section.isPage
                    ? renderListItem(x.section, true, location.pathname)
                    : renderListSection(x.section, x.children, currentUserRights, location.pathname);
            }).value();
    }, [currentUserRights, location.pathname]);

    return ( 
        <div className={'fdc hfill'}>
            <NavBar
                env={getEnvironment()}
                version={VERSION.version}
                currentUser={currentUser}
                signOut={signOut}
                breadcrumbs={breadcrumbs}
                updateVersion={updateVersion}
                openDrawer={openDrawer}
                closeDrawer={closeDrawer}
                openUserSettings={openUserSettings}
                closeUserSettings={closeUserSettings}
                isLatestVersion  
                headings={[siteHeading]}
                userSettingsDialog={userSettings}             
            />
            <div className={'fdr flx1 oyh'}>
                <NavDrawer
                    env={getEnvironment()} 
                    path={location.pathname}
                    currentUser={currentUser}
                    isOpen={isNavDrawerOpen}
                    isLoading={false}
                    sections={sections}
                    logOut={signOut}
                    closeDrawer={closeDrawer}
                    onHelpClick={openSupportTicketDialog}
                    zz2FreshSupportTicketDialog={supportTicketDialog}
                />
                <Routes />
            </div>
        </div>
    );
};

export default NavFrame;