import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '@zz2/zz2-ui';

export default class FreshServiceActions {
    public static setIsLoading = createAction('FRESH_SERVICE_SET_LOADING', withPayloadType<boolean>());
    public static setIsSupportTicketDialogOpen = createAction('FRESH_SERVICE_SET_OPEN_DIALOG', withPayloadType<boolean>());
    public static setSupportTicketTitle = createAction('FRESH_SERVICE_SET_TITLE', withPayloadType<string>());
    public static setSupportTicketDescription = createAction('FRESH_SERVICE_SET_DESCRIPTION', withPayloadType<string>());
    public static reset = createAction('FRESH_SERVICE_RESET');
}
