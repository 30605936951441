import AuthActions from './actions';
import * as localStorageService from '../../service/localStorageService';
import AuthHttpService from '../../service/http/authHttpService';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkApi } from '../../@types/redux';
import GeneralThunks from '../general/thunks';
import { navPath, navReplace } from '../nav/actions';
import { IUserToken } from '../../@types/model/auth/userToken/userToken';
import RightActions from '../right/actions';
import DataActions from '../masterData/actions';
import { GoogleLoginModel } from '../../@types/model/auth/login/GoogleLoginModel';

export default class AuthThunks {

    /**
     * Performs log out request with API then sets the auth state accordingly.
     */
    public static logOut = createAsyncThunk<
    void,
    undefined,
    ThunkApi>(
        'AUTH_LOG_OUT',
        async (params, thunkApi) => {
            try {
                await AuthHttpService.logout();
                
                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Success'));
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while logging out.', e }));
            } finally {
                thunkApi.dispatch(AuthActions.logout());
                thunkApi.dispatch(DataActions.reset());
                await localStorageService.setLocalStorageSession(null);
                navReplace('/login');
            }
        },
    );

    /**
     * Performs log in request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
     * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
     * init).
     *
     * @param code
     */
    public static logIn = createAsyncThunk<
    IUserToken | null,
    {
        login : GoogleLoginModel;
    },
    ThunkApi>(
        'AUTH_LOG_IN',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(AuthActions.setLoggingIn(true));

                const res = await AuthHttpService.googleLogin(params.login);
                await localStorageService.setLocalStorageSession(res.data);

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while logging in.', e }));
                return null;
            } finally {
                thunkApi.dispatch(AuthActions.setLoggingIn(false));
            }
        },
    );

    /**
     * Performs log in request with API then sets the interceptor (using auth token) and auth state accordingly. Kicks off
     * the setup for local storage service to store the session and, in turn, firing its callback (that is set during app
     * init).
     *
     * @param emailOrUsername
     * @param password
     */
    public static manualLogIn = createAsyncThunk<
    IUserToken | null,
    {
        emailOrUsername : string;
        password : string;
    },
    ThunkApi>(
        'AUTH_MANUAL_LOGIN',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(AuthActions.setLoggingIn(true));

                const res = await AuthHttpService.logInManual(params.emailOrUsername, params.password);

                await localStorageService.setLocalStorageSession(res.data);
                navReplace('/home');
                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Success'));

                return res.data;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while logging in.', e }));
                return null;
            } finally {
                thunkApi.dispatch(AuthActions.setLoggingIn(false));
            }
        },
    );

    /**
     * Performs request for password reset email
     *
     * @param emailOrUsername
     */
    public static requestForgottenPassword = createAsyncThunk<
    boolean | null,
    {
        emailOrUsername : string;
    },
    ThunkApi>(
        'REQUEST_FORGOTTEN_PASSWORD',
        async (params, thunkApi) => {
            thunkApi.dispatch(RightActions.setIsLoading(true));
            try {
                const res = await AuthHttpService.requestForgottenPasswordEmail(params.emailOrUsername);
                if (res.data) {
                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Password recovery submitted, please check your email'));
                    return res.data;
                } else {
                    thunkApi.dispatch(GeneralThunks.showErrorSnackbar({defaultMessage: 'Password recovery could not be submitted'}));
                    return null;
                }
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while recovering password.', e }));
                return null;
            } finally {
                thunkApi.dispatch(AuthActions.setLoggingIn(false));
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

    /**
     * Performs request for password reset using recovery code received in email
     *
     * @param emailOrUsername
     */
    public static passwordReset = createAsyncThunk<
    boolean | null,
    {
        recoveryCode : string;
        password : string;
        email : string;
    },
    ThunkApi>(
        'RESET_PASSWORD',
        async (params, thunkApi) => {
            thunkApi.dispatch(RightActions.setIsLoading(true));
            try {
                const res = await AuthHttpService.resetPassword(params.recoveryCode, params.email, params.password);
                if (res.data) {
                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Password Change Successful'));
                    return res.data;
                } else {
                    thunkApi.dispatch(GeneralThunks.showErrorSnackbar({defaultMessage: 'Password recovery could not be reset'}));
                    return null;
                }
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while submitting password reset request.', e }));
                return null;
            } finally {
                thunkApi.dispatch(AuthActions.setLoggingIn(false));
                thunkApi.dispatch(RightActions.setIsLoading(false));
            }
        },
    );

        /**
     * Performs log out request with API for the provided user IDs then sets the auth state accordingly.
     * 
     * @param userIds
     */
        public static logoutUsers = createAsyncThunk(
            'auth/logoutUsers',
            async (params : {
                currentUserId : number;
                userIds : Array<number>;
            }, ThunkApi) => {
                try {    
                    ThunkApi.dispatch(AuthActions.setLoggingIn(true));
                    await AuthHttpService.logoutUsers(params.userIds);
        
                    if (params.userIds.find(x => x === params.currentUserId)) {
                        await localStorageService.setLocalStorageSession(null);
                        
                        ThunkApi.dispatch(AuthActions.logout());
                        navPath('/login'); 
                    }
    
                } catch (e) {
                    ThunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while logging out users.', e: e }));
                    await localStorageService.setLocalStorageSession(null);
                } finally {
                    ThunkApi.dispatch(AuthActions.setLoggingIn(false));
                }
            }
        )
}
