import { createReducer } from '@reduxjs/toolkit';
import LogActions from './actions';
import { ILog } from '../../@types/model/logging/Log';

export interface ILogState {
    isLoading : boolean;
    logData : Array<ILog> | null;
}

const initialState = {
    isLoading: false,
    logData: null,
};

const freshServiceReducer = createReducer<ILogState>(initialState, (builder) => {
    builder.addCase(LogActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(LogActions.setLogData, (state, action) => {
        state.logData = action.payload;
    });
});

export default freshServiceReducer;
