import { createAsyncThunk } from '@reduxjs/toolkit';
import { ArrayHelper } from '@zz2/zz2-ui';
import { IActivityType } from '../../@types/model/masterData/activityType/activityType';
import { IBatchStatus } from '../../@types/model/masterData/batchStatus/batchStatus';
import { IBatchType } from '../../@types/model/masterData/batchType/batchType';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IDevice } from '../../@types/model/masterData/device/device';
import { IIngredient } from '../../@types/model/masterData/ingredient/ingredient';
import { ILocation } from '../../@types/model/masterData/location/location';
import { IMeasurementType } from '../../@types/model/masterData/measurementType/measurementType';
import { IReason } from '../../@types/model/masterData/reason/reason';
import { IRecipe } from '../../@types/model/masterData/recipe/recipe';
import { ISite } from '../../@types/model/masterData/site/site';
import { IUnitOfMeasure } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { ThunkApi } from '../../@types/redux';
import DataHttpService from '../../service/http/dataHttpService';
import GeneralThunks from '../general/thunks';
import DataActions from './actions';

export default class MasterDataThunks {
    /**
     * Retrieves the list of sites from the API, updating the redux state once complete. Performs no
     * action if no sites have been retrieved.
     *
     * @returns {Array<ISite> | null}
     */
    public static getSiteList = createAsyncThunk<
        Array<ISite> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_SITES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await DataHttpService.masterDataSiteGetList();


                    thunkApi.dispatch(DataActions.setSiteData(res.data));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading varieties.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            },
        );

    /**
     * Retrieves the list of departments from the API, updating the redux state once complete. Performs no
     * action if no departments have been retrieved.
     *
     * @param {number} siteId
     * @returns {Array<IDepartment> | null}
     */
    public static getDepartmentList = createAsyncThunk<
        Array<IDepartment> | null,
        number | undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_DEPARTMENTS',
            async (siteId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));

                    const res = await DataHttpService.masterDataDepartmentGetList(siteId ?? 0);

                    thunkApi.dispatch(DataActions.setDepartmentData(res.data));

                    return res.data;
                } catch (e) {
                    thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading departments.', e }));
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            }
        );

    /**
     * Retrieves the list of locations from the API, updating the redux state once complete. Performs no
     * action if no locations have been retrieved.
     *
     * @param {number} departmentId
     * @returns {Array<ILocation> | null}
     */
    public static getLocationList = createAsyncThunk<
        Array<ILocation> | null,
        number | undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_LOCATIONS_BY_DEPARTMENT',
            async (departmentId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
        
                    const res = await DataHttpService.masterDataLocationGetList(departmentId ?? 0);
        
                    thunkApi.dispatch(DataActions.setLocationData(res.data));
        
                    return res.data;
                } catch (e) {
                    if (e) {
                        thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading locations.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            }
        );

    /**
     * Retrieves the list of locations from the API, updating the redux state once complete. Performs no
     * action if no locations have been retrieved.
     *
     * @param {number} siteId
     * @returns {Array<ILocation> | null}
     */
    public static getLocationListBySite = createAsyncThunk<
        Array<ILocation> | null,
        number,
        ThunkApi>(
            'MASTER_DATA_LOAD_LOCATIONS_BY_SITE',
            async (siteId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
        
                    const res = await DataHttpService.masterDataLocationGetListBySite(siteId);
        
                    thunkApi.dispatch(DataActions.setLocationData(res.data));
        
                    return res.data;
                } catch (e) {
                    if (e) {
                        thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading locations.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            }
        );

    /**
     * Retrieves the list of devices from the API, updating the redux state once complete. Performs no
     * action if no devices have been retrieved.
     *
     * @param {number} locationId
     * @returns {Array<IActivityType> | null}
     */
    public static getDeviceList = createAsyncThunk<
        Array<IDevice> | null,
        number | undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_DEVICES',
            async (locationId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
        
                    const res = await DataHttpService.masterDataDeviceGetList(locationId ?? 0);
        
                    thunkApi.dispatch(DataActions.setDeviceData(res.data));
        
                    return res.data;
                } catch (e) {
                    if (e) {
                        thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading devices.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            }
        );

    /**
     * Retrieves the list of devices from the API, updating the redux state once complete. Performs no
     * action if no devices have been retrieved.
     *
     * @param {number} siteId
     * @returns {Array<IActivityType> | null}
     */
    public static getDeviceListBySiteId = createAsyncThunk<
        Array<IDevice> | null,
        number | undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_DEVICES_BY_SITE',
            async (siteId, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
        
                    const res = await DataHttpService.masterDataDeviceGetListBySiteId(siteId ?? 0);
        
                    thunkApi.dispatch(DataActions.setDeviceStatusData(res.data));
        
                    return res.data;
                } catch (e) {
                    if (e) {
                        thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading devices.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            }
        );

    /**
     * Retrieves the list of activity types from the API, updating the redux state once complete. Performs no
     * action if no activity types have been retrieved.
     *
     * @returns {Array<IActivityType> | null}
     */
    public static getActivityTypeList = createAsyncThunk<
        Array<IActivityType> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_ACTIVITY_TYPES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
        
                    const res = await DataHttpService.masterDataActivityTypeGetList();

                    thunkApi.dispatch(DataActions.setActivityTypeData(res.data));
        
                    return res.data;
                } catch (e) {
                    if (e) {
                        thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading activity types.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            }  
        );

    /**
     * Retrieves the list of batch types from the API, updating the redux state once complete. Performs no
     * action if no batch types have been retrieved.
     *
     * @returns {Array<IBatchType> | null}
     */
    public static getBatchTypeList = createAsyncThunk<
        Array<IBatchType> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_BATCH_TYPES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
        
                    const res = await DataHttpService.masterDataBatchTypeGetList();
        
                    thunkApi.dispatch(DataActions.setBatchTypeData(res.data));
        
                    return res.data;
                } catch (e) {
                    if (e) {
                        thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading activity types.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            }
        );

    /**
     * Retrieves the list of batch statuses from the API, updating the redux state once complete. Performs no
     * action if no batch statuses have been retrieved.
     *
     * @returns {Array<IBatchStatus> | null}
     */
    public static getBatchStatusList = createAsyncThunk<
        Array<IBatchStatus> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_BATCH_STATUSES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
        
                    const res = await DataHttpService.masterDataBatchStatusGetList();
        
                    thunkApi.dispatch(DataActions.setBatchStatusData(res.data));
        
                    return res.data;
                } catch (e) {
                    if (e) {
                        thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading batch statuses.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            }
        );

    /**
     * Retrieves the list of measurement types from the API, updating the redux state once complete. Performs no
     * action if no measurement types have been retrieved.
     *
     * @returns {Array<IMeasurementType> | null}
     */
    public static getMeasurementTypeList = createAsyncThunk<
        Array<IMeasurementType> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_MEASUREMENT_TYPES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
        
                    const res = await DataHttpService.masterDataMeasurementTypeGetList();
        
                    thunkApi.dispatch(DataActions.setMeasurementTypeData(res.data));

                    return res.data;
                } catch (e) {
                    if (e) {
                        thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading measurement types.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            }
        );

    /**
     * Retrieves the list of reasons from the API, updating the redux state once complete. Performs no
     * action if no reasons have been retrieved.
     *
     * @returns {Array<IReason> | null}
     */
    public static getReasonList = createAsyncThunk<
        Array<IReason> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_REASONS',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
        
                    const res = await DataHttpService.masterDataReasonGetList();
        
                    thunkApi.dispatch(DataActions.setReasonData(res.data));
        
                    return res.data;
                } catch (e) {
                    if (e) {
                        thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading reasons.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            }
        );

    /**
     * Retrieves the list of recipes from the API, updating the redux state once complete. Performs no
     * action if no recipes have been retrieved.
     *
     * @returns {Array<IRecipe> | null}
     */
    public static getRecipeList = createAsyncThunk<
        Array<IRecipe> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_RECIPES',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
        
                    const res = await DataHttpService.masterDataRecipeGetList();
        
                    thunkApi.dispatch(DataActions.setRecipeData(res.data));
        
                    return res.data;
                } catch (e) {
                    if (e) {
                        thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading recipes.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            }
        );

    /**
     * Retrieves the list of ingredients from the API, updating the redux state once complete. Performs no
     * action if no ingredients have been retrieved.
     *
     * @returns {Array<IIngredient> | null}
     */
    public static getIngredientList = createAsyncThunk<
        Array<IIngredient> | null,
        undefined,
        ThunkApi>(
            'MASTER_DATA_LOAD_INGREDIENTS',
            async (params, thunkApi) => {
                try {
                    thunkApi.dispatch(DataActions.setIsLoading(true));
        
                    const res = await DataHttpService.masterDataIngredientGetList();
        
                    thunkApi.dispatch(DataActions.setIngredientData(res.data));
        
                    return res.data;
                } catch (e) {
                    if (e) {
                        thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading ingredients.', e }));
                    }
                    return null;
                } finally {
                    thunkApi.dispatch(DataActions.setIsLoading(false));
                }
            }
        );

    /**
     * Retrieves the list of unit of measures from the API, updating the redux state once complete. Performs no
     * action if no unit of measures have been retrieved.
     *
     * @returns {Array<IUnitOfMeasure> | null}
     */
    public static getUnitOfMeasureList = createAsyncThunk<
    Array<IUnitOfMeasure> | null,
    undefined,
    ThunkApi>(
        'MASTER_DATA_LOAD_UNIT_OF_MEASURES',
        async (params, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.masterDataUnitOfMeasureGetList();
    
                thunkApi.dispatch(DataActions.setUnitOfMeasureData(res.data));
    
                return res.data;
            } catch (e) {
                if (e) {
                    thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading unit of measures.', e }));
                }
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        }
    );

    /****************************** UPSERT *********************************/

    /**
     * Inserts or updates the activity type information of a activity type upsert, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IActivityType} activityType
     * @returns {IActivityType | null}
     */
    public static upsertActivityType = createAsyncThunk<
    IActivityType | null,
    IActivityType,
    ThunkApi>(
        'MASTER_DATA_UPSERT_ACTIVITY_TYPE',
        async (activityTypeUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const activityTypes = state.masterData.activityTypeData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.activityTypeSave(activityTypeUpsert);

                if (res.data) {
                    const newList = ArrayHelper.upsertElement(activityTypes, res.data, a => a.id === res.data!.id);
                    thunkApi.dispatch(DataActions.setActivityTypeData(newList));

                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the activity type entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts or updates the batch type information of a batch type upsert, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IBatchType} batchType
     * @returns {IBatchType | null}
     */
    public static upsertBatchType = createAsyncThunk<
    IBatchType | null,
    IBatchType,
    ThunkApi>(
        'MASTER_DATA_UPSERT_BATCH_TYPE',
        async (batchTypeUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const batchTypes = state.masterData.batchTypeData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.batchTypeSave(batchTypeUpsert);

                if (res.data) {
                    const newList = ArrayHelper.upsertElement(batchTypes, res.data, a => a.id === res.data!.id);
                    thunkApi.dispatch(DataActions.setBatchTypeData(newList));
                    
                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the batch type entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts or updates the batch status information of a batch status upsert, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IBatchStatus} batchStatus
     * @returns {IBatchStatus | null}
     */
    public static upsertBatchStatus = createAsyncThunk<
    IBatchStatus | null,
    IBatchStatus,
    ThunkApi>(
        'MASTER_DATA_UPSERT_BATCH_STATUS',
        async (batchStatusUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const batchStatus = state.masterData.batchStatusData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.batchStatusSave(batchStatusUpsert);

                if (res.data) {
                    const newList = ArrayHelper.upsertElement(batchStatus, res.data, a => a.id === res.data!.id);
                    thunkApi.dispatch(DataActions.setBatchStatusData(newList));

                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the batch status entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts or updates the device information of a device upsert, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IDevice} device
     * @returns {IDevice | null}
     */
    public static upsertDevice = createAsyncThunk<
    IDevice | null,
    IDevice,
    ThunkApi>(
        'MASTER_DATA_UPSERT_DEVICE',
        async (deviceUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const devices = state.masterData.deviceData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.deviceSave(deviceUpsert);

                if (res.data) {
                    const newList = ArrayHelper.upsertElement(devices, res.data, a => a.id === res.data!.id);
                    thunkApi.dispatch(DataActions.setDeviceData(newList));
    
                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the device entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts or updates the measurement type information of a measurement type upsert, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IMeasurementType} measurementType
     * @returns {IMeasurementType | null}
     */
    public static upsertMeasurementType = createAsyncThunk<
    IMeasurementType | null,
    IMeasurementType,
    ThunkApi>(
        'MASTER_DATA_UPSERT_MEASUREMENT_TYPE',
        async (measurementTypeUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const measurementTypes = state.masterData.measurementTypeData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.measurementTypeSave(measurementTypeUpsert);

                if (res.data) {
                    const newList = ArrayHelper.upsertElement(measurementTypes, res.data, a => a.id === res.data!.id);
                    thunkApi.dispatch(DataActions.setMeasurementTypeData(newList));

                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the batch type entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts or updates the reason information of a reason upsert, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IReason} reason
     * @returns {IReason | null}
     */
    public static upsertReason = createAsyncThunk<
    IReason | null,
    IReason,
    ThunkApi>(
        'MASTER_DATA_UPSERT_REASON',
        async (reasonUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const reasons = state.masterData.reasonData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.reasonSave(reasonUpsert);

                if (res.data) {
                    const newList = ArrayHelper.upsertElement(reasons, res.data, a => a.id === res.data!.id);
                    thunkApi.dispatch(DataActions.setReasonData(newList));

                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the reason entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts or updates the recipe information of a recipe upsert, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IRecipe} recipe
     * @returns {IRecipe | null}
     */
    public static upsertRecipe = createAsyncThunk<
    IRecipe | null,
    IRecipe,
    ThunkApi>(
        'MASTER_DATA_UPSERT_RECIPE',
        async (recipeUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const recipes = state.masterData.recipeData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.recipeSave(recipeUpsert);
                
                if (res.data) {
                    const newList = ArrayHelper.upsertElement(recipes, res.data, a => a.id === res.data!.id);
    
                    thunkApi.dispatch(DataActions.setRecipeData(newList));
                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the recipe entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts or updates the unit of measure information of a unit of measure upsert, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IUnitOfMeasure} unitOfMeasure
     * @returns {IUnitOfMeasure | null}
     */
    public static upsertUnitOfMeasure = createAsyncThunk<
    IUnitOfMeasure | null,
    IUnitOfMeasure,
    ThunkApi>(
        'MASTER_DATA_UPSERT_UNIT_OF_MEASURE',
        async (unitOfMeasureUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const unitOfMeasures = state.masterData.unitOfMeasureData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.unitOfMeasureSave(unitOfMeasureUpsert);

                if (res.data) {
                    const newList = ArrayHelper.upsertElement(unitOfMeasures, res.data, a => a.id === res.data!.id);
                    thunkApi.dispatch(DataActions.setUnitOfMeasureData(newList));

                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the unitOfMeasure entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts or updates the ingredient information of a ingredient upsert, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IIngredient} ingredient
     * @returns {IIngredient | null}
     */
    public static upsertIngredient = createAsyncThunk<
    IIngredient | null,
    IIngredient,
    ThunkApi>(
        'MASTER_DATA_UPSERT_INGREDIENT',
        async (siteUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const ingredients = state.masterData.ingredientData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.ingredientSave(siteUpsert);

                if (res.data) {
                    const newList = ArrayHelper.upsertElement(ingredients, res.data, a => a.id === res.data!.id);
                    thunkApi.dispatch(DataActions.setIngredientData(newList));

                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the ingredient entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts or updates the site information of a site upsert, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ISite} site
     * @returns {ISite | null}
     */
    public static upsertSite = createAsyncThunk<
    ISite | null,
    ISite,
    ThunkApi>(
        'MASTER_DATA_UPSERT_SITE',
        async (siteUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const sites = state.masterData.siteData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.siteSave(siteUpsert);

                if (res.data) {
                    const newList = ArrayHelper.upsertElement(sites, res.data, a => a.id === res.data!.id);
                    thunkApi.dispatch(DataActions.setSiteData(newList));

                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the site entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts or updates the department information of a department upsert, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {IDepartment} department
     * @returns {IDepartment | null}
     */
    public static upsertDepartment = createAsyncThunk<
    IDepartment | null,
    IDepartment,
    ThunkApi>(
        'MASTER_DATA_UPSERT_DEPARTMENT',
        async (departmentUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const departments = state.masterData.departmentData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.departmentSave(departmentUpsert);

                if (res.data) {
                    const newList = ArrayHelper.upsertElement(departments, res.data, a => a.id === res.data!.id);
                    thunkApi.dispatch(DataActions.setDepartmentData(newList));

                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the department entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Inserts or updates the location information of a location upsert, calling the API. Once complete, the redux
     * state is updated to reflect the change.
     *
     * @param {ILocation} location
     * @returns {ILocation | null}
     */
    public static upsertLocation = createAsyncThunk<
    ILocation | null,
    ILocation,
    ThunkApi>(
        'MASTER_DATA_UPSERT_LOCATION',
        async (locationUpsert, thunkApi) => {
            try {
                const state = thunkApi.getState();
                const locations = state.masterData.locationData ?? [];

                thunkApi.dispatch(DataActions.setIsLoading(true));
    
                const res = await DataHttpService.locationSave(locationUpsert);

                if (res.data) {
                    const newList = ArrayHelper.upsertElement(locations, res.data, a => a.id === res.data!.id);
                    thunkApi.dispatch(DataActions.setLocationData(newList));

                    thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry saved successfully.'));
                    return res.data;
                }

                return null;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while saving the location entry.', e }));
                return null;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /****************************** DELETE *********************************/

    /**
     * Deletes an activity type, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} activityTypeId
     * @returns {boolean}
     */
    public static deleteActivityType = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_ACTIVITY_TYPE',
        async (activityTypeId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DataHttpService.activityTypeDelete(activityTypeId);

                const activityTypeData : Array<IActivityType> = thunkApi.getState().masterData.activityTypeData ?? [];
                const deletedActivityType = activityTypeData.find(x => x.id === activityTypeId);

                if (deletedActivityType) {
                    const updatedActivityType : IActivityType = {
                        ...deletedActivityType,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(activityTypeData, updatedActivityType, a => a.id === activityTypeId);

                    thunkApi.dispatch(DataActions.setActivityTypeData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting activity type entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes an batch type, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} batchTypeId
     * @returns {boolean}
     */
    public static deleteBatchType = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_BATCH_TYPE',
        async (batchTypeId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DataHttpService.batchTypeDelete(batchTypeId);

                const batchTypeData : Array<IBatchType> = thunkApi.getState().masterData.batchTypeData ?? [];
                const deletedBatchType = batchTypeData.find(x => x.id === batchTypeId);

                if (deletedBatchType) {
                    const updatedBatchType : IBatchType = {
                        ...deletedBatchType,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(batchTypeData, updatedBatchType, a => a.id === batchTypeId);

                    thunkApi.dispatch(DataActions.setBatchTypeData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting batch type entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes an batch status, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} batchStatusId
     * @returns {boolean}
     */
    public static deleteBatchStatus = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_BATCH_STATUS',
        async (batchStatusId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DataHttpService.batchStatusDelete(batchStatusId);

                const batchStatusData : Array<IBatchStatus> = thunkApi.getState().masterData.batchStatusData ?? [];
                const deletedBatchStatus = batchStatusData.find(x => x.id === batchStatusId);

                if (deletedBatchStatus) {
                    const updatedBatchStatus : IBatchStatus = {
                        ...deletedBatchStatus,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(batchStatusData, updatedBatchStatus, a => a.id === batchStatusId);

                    thunkApi.dispatch(DataActions.setBatchStatusData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting batch status entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a device, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} deviceId
     * @returns {boolean}
     */
    public static deleteDevice = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_DEVICE',
        async (deviceId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DataHttpService.deviceDelete(deviceId);

                const deviceData : Array<IDevice> = thunkApi.getState().masterData.deviceData ?? [];
                const deletedDevice = deviceData.find(x => x.id === deviceId);

                if (deletedDevice) {
                    const updatedDevice : IDevice = {
                        ...deletedDevice,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(deviceData, updatedDevice, a => a.id === deviceId);

                    thunkApi.dispatch(DataActions.setDeviceData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting device entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a measurement type, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} measurementTypeId
     * @returns {boolean}
     */
    public static deleteMeasurementType = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_YOOOOO',
        async (measurementTypeId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DataHttpService.measurementTypeDelete(measurementTypeId);

                const measurementTypeData : Array<IMeasurementType> = thunkApi.getState().masterData.measurementTypeData ?? [];
                const deletedMeasurementType = measurementTypeData.find(x => x.id === measurementTypeId);

                if (deletedMeasurementType) {
                    const updatedMeasurementType : IMeasurementType = {
                        ...deletedMeasurementType,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(measurementTypeData, updatedMeasurementType, a => a.id === measurementTypeId);

                    thunkApi.dispatch(DataActions.setMeasurementTypeData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting measurementType entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a reason, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} reasonId
     * @returns {boolean}
     */
    public static deleteReason = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_REASON',
        async (reasonId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DataHttpService.reasonDelete(reasonId);

                const reasonData : Array<IReason> = thunkApi.getState().masterData.reasonData ?? [];
                const deletedReason = reasonData.find(x => x.id === reasonId);

                if (deletedReason) {
                    const updatedReason : IReason = {
                        ...deletedReason,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(reasonData, updatedReason, a => a.id === reasonId);

                    thunkApi.dispatch(DataActions.setReasonData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting reason entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a recipe, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} recipeId
     * @returns {boolean}
     */
    public static deleteRecipe = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_RECIPE',
        async (recipeId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DataHttpService.recipeDelete(recipeId);

                const recipeData : Array<IRecipe> = thunkApi.getState().masterData.recipeData ?? [];
                const deletedRecipe = recipeData.find(x => x.id === recipeId);

                if (deletedRecipe) {
                    const updatedRecipe : IRecipe = {
                        ...deletedRecipe,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(recipeData, updatedRecipe, a => a.id === recipeId);

                    thunkApi.dispatch(DataActions.setRecipeData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting recipe entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a ingredient, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} ingredientId
     * @returns {boolean}
     */
    public static deleteIngredient = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_INGREDIENT',
        async (ingredientId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DataHttpService.ingredientDelete(ingredientId);

                const ingredientData : Array<IIngredient> = thunkApi.getState().masterData.ingredientData ?? [];
                const deletedIngredient = ingredientData.find(x => x.id === ingredientId);

                if (deletedIngredient) {
                    const updatedIngredient : IIngredient = {
                        ...deletedIngredient,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(ingredientData, updatedIngredient, a => a.id === ingredientId);

                    thunkApi.dispatch(DataActions.setIngredientData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting ingredient entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a unit of measure, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} unitOfMeasureId
     * @returns {boolean}
     */
    public static deleteUnitOfMeasure = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_UNIT_OF_MEASURE',
        async (unitOfMeasureId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DataHttpService.unitOfMeasureDelete(unitOfMeasureId);

                const unitOfMeasureData : Array<IUnitOfMeasure> = thunkApi.getState().masterData.unitOfMeasureData ?? [];
                const deletedUnitOfMeasure = unitOfMeasureData.find(x => x.id === unitOfMeasureId);

                if (deletedUnitOfMeasure) {
                    const updatedUnitOfMeasure : IUnitOfMeasure = {
                        ...deletedUnitOfMeasure,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(unitOfMeasureData, updatedUnitOfMeasure, a => a.id === unitOfMeasureId);

                    thunkApi.dispatch(DataActions.setUnitOfMeasureData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting unit of measure entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a location, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} locationId
     * @returns {boolean}
     */
    public static deleteLocation = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_LOCATION',
        async (locationId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DataHttpService.locationDelete(locationId);

                const locationData : Array<ILocation> = thunkApi.getState().masterData.locationData ?? [];
                const deletedLocation = locationData.find(x => x.id === locationId);

                if (deletedLocation) {
                    const updatedLocation : ILocation = {
                        ...deletedLocation,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(locationData, updatedLocation, a => a.id === locationId);

                    thunkApi.dispatch(DataActions.setLocationData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting location entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a site, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} siteId
     * @returns {boolean}
     */
    public static deleteSite = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_SITE',
        async (siteId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DataHttpService.siteDelete(siteId);

                const siteData : Array<ISite> = thunkApi.getState().masterData.siteData ?? [];
                const deletedSite = siteData.find(x => x.id === siteId);

                if (deletedSite) {
                    const updatedSite : ISite = {
                        ...deletedSite,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(siteData, updatedSite, a => a.id === siteId);

                    thunkApi.dispatch(DataActions.setSiteData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting site entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );

    /**
     * Deletes a department, calling the API. Once complete, the change is reflected in the redux state.
     *
     * @param {number} departmentId
     * @returns {boolean}
     */
    public static deleteDepartment = createAsyncThunk<
    boolean,
    number,
    ThunkApi>(
        'MASTER_DATA_DELETE_DEPARTMENT',
        async (departmentId, thunkApi) => {
            try {
                thunkApi.dispatch(DataActions.setIsLoading(true));
                
                await DataHttpService.departmentDelete(departmentId);

                const departmentData : Array<IDepartment> = thunkApi.getState().masterData.departmentData ?? [];
                const deletedDepartment = departmentData.find(x => x.id === departmentId);

                if (deletedDepartment) {
                    const updatedDepartment : IDepartment = {
                        ...deletedDepartment,
                        isActive: false,
                    };
                    const newList = ArrayHelper.upsertElement(departmentData, updatedDepartment, a => a.id === departmentId);

                    thunkApi.dispatch(DataActions.setDepartmentData(newList));
                }

                thunkApi.dispatch(GeneralThunks.showSuccessSnackbar('Entry deleted successfully.'));

                return true;
            } catch (e) {
                thunkApi.dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while deleting department entry.', e }));
                return false;
            } finally {
                thunkApi.dispatch(DataActions.setIsLoading(false));
            }
        },
    );
}