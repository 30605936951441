import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '@zz2/zz2-ui';
import { IBatch } from '../../@types/model/batch/batch';
import { IBatchIngredientBridge } from '../../@types/model/batch/bridges/batchIngredientBridge';

export default class BatchActions {
    public static setIsLoading = createAction('BATCH_SET_LOADING', withPayloadType<boolean>());
    public static setIsBatchBeingEdited = createAction('SET_IS_BATCH_BEING_EDITED', withPayloadType<boolean>());
    public static setBatchData = createAction('BATCH_SET_LIST', withPayloadType<Array<IBatch> | null>());
    public static setSelectedBatch = createAction('BATCH_SET_SELECTED', withPayloadType<IBatch | null>());
    public static setSelectedBatchIngredientBridge = createAction('BATCH_SET_SELECTED_INGREDIENT_BRIDGE', withPayloadType<IBatchIngredientBridge | null>());
    public static setBatchCreateRecipePopupOpen = createAction('BATCH_SET_CREATE_POPUP_OPEN', withPayloadType<boolean>());
    public static setIsBatchRecipeEditFormSubmitting = createAction('BATCH_SET_RECIPE_EDIT_FORM_SUBMITTING_STATE', withPayloadType<boolean>());
    public static setLoadBatchReports = createAction('BATCH_SET_LOAD_BATCH_REPORTS', withPayloadType<boolean>());
    public static reset = createAction('BATCH_RESET');
}