import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '@zz2/zz2-ui';
import { ILatestMeasurement } from '../../@types/model/measurement/latestMeasurement';
import { IMeasurement } from '../../@types/model/measurement/measurement';

export default class MeasurementActions {
    public static setIsLoading = createAction('MEASUREMENT_SET_LOADING', withPayloadType<boolean>());
    public static setMeasurementData = createAction('MEASUREMENT_SET_LIST', withPayloadType<Array<IMeasurement> | null>());
    public static setLatestBatchMeasurementData = createAction('MEASUREMENT_SET_LATEST_BATCH_MEASUREMENT_LIST', withPayloadType<Array<ILatestMeasurement>>());
    public static setEditDialogOpenState = createAction('MEASUREMENT_SET_EDIT_DIALOG_OPEN_STATE', withPayloadType<boolean>());
    public static setIsAddMeasurementFormSubmitting = createAction('MEASUREMENT_SET_ADD_FORM_SUBMITTING_STATE', withPayloadType<boolean>());
    public static reset = createAction('MEASUREMENT_RESET');
}