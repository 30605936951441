import { createAction } from '@reduxjs/toolkit';
import { withPayloadType } from '@zz2/zz2-ui';
import { IRight } from '../../@types/model/auth/right/right';
import { IUser } from '../../@types/model/auth/user/user';

export default class RightActions {
    public static setIsLoading = createAction('RIGHT_SET_LOADING', withPayloadType<boolean>());
    public static setRightData = createAction('RIGHT_SET_LIST', withPayloadType<Array<IRight>>());
    public static setUserData = createAction('USER_SET_LIST', withPayloadType<Array<IUser>>());
    public static reset = createAction('RIGHT_RESET');
}