import { IActivityType } from '../../@types/model/masterData/activityType/activityType';
import { IBatchStatus } from '../../@types/model/masterData/batchStatus/batchStatus';
import { IBatchType } from '../../@types/model/masterData/batchType/batchType';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IDevice } from '../../@types/model/masterData/device/device';
import { IIngredient } from '../../@types/model/masterData/ingredient/ingredient';
import { ILocation } from '../../@types/model/masterData/location/location';
import { IMeasurementType } from '../../@types/model/masterData/measurementType/measurementType';
import { IReason } from '../../@types/model/masterData/reason/reason';
import { IRecipe } from '../../@types/model/masterData/recipe/recipe';
import { ISite } from '../../@types/model/masterData/site/site';
import { IUnitOfMeasure } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import DataActions from './actions';
import { createReducer } from '@reduxjs/toolkit';

export interface IDataState {
    isLoading : boolean;
    siteData : Array<ISite> | null;
    selectedSiteId : number | null;
    departmentData : Array<IDepartment> | null;
    locationData : Array<ILocation> | null;
    selectedDevice : IDevice | null;
    deviceData : Array<IDevice> | null;
    deviceStatusData : Array<IDevice> | null;
    activityTypeData : Array<IActivityType> | null;
    batchTypeData : Array<IBatchType> | null;
    batchStatusData : Array<IBatchStatus> | null;
    measurementTypeData : Array<IMeasurementType> | null;
    reasonData : Array<IReason> | null;
    recipeData : Array<IRecipe> | null;
    unitOfMeasureData : Array<IUnitOfMeasure> | null;
    ingredientData : Array<IIngredient> | null;
}

const initialState = {
    isLoading: false,
    siteData: null,
    selectedSiteId: null,
    departmentData: null,
    locationData: null,
    selectedDevice: null,
    deviceData: null,
    deviceStatusData: null,
    activityTypeData: null,
    batchTypeData: null,
    batchStatusData: null,
    measurementTypeData: null,
    reasonData: null,
    recipeData: null,
    unitOfMeasureData: null,
    ingredientData: null,
};

const dataReducer = createReducer<IDataState>(initialState, (builder) => {
    builder.addCase(DataActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(DataActions.setSiteData, (state, action) => {
        state.siteData = action.payload;
    });
    builder.addCase(DataActions.setDepartmentData, (state, action) => {
        state.departmentData = action.payload;
    });
    builder.addCase(DataActions.setLocationData, (state, action) => {
        state.locationData = action.payload;
    });
    builder.addCase(DataActions.setDeviceData, (state, action) => {
        state.deviceData = action.payload;
    });
    builder.addCase(DataActions.setDeviceStatusData, (state, action) => {
        state.deviceStatusData = action.payload;
    });
    builder.addCase(DataActions.setActivityTypeData, (state, action) => {
        state.activityTypeData = action.payload;
    });
    builder.addCase(DataActions.setBatchTypeData, (state, action) => {
        state.batchTypeData = action.payload;
    });
    builder.addCase(DataActions.setBatchStatusData, (state, action) => {
        state.batchStatusData = action.payload;
    });
    builder.addCase(DataActions.setMeasurementTypeData, (state, action) => {
        state.measurementTypeData = action.payload;
    });
    builder.addCase(DataActions.setReasonData, (state, action) => {
        state.reasonData = action.payload;
    });
    builder.addCase(DataActions.setRecipeData, (state, action) => {
        state.recipeData = action.payload;
    });
    builder.addCase(DataActions.setIngredientData, (state, action) => {
        state.ingredientData = action.payload;
    });
    builder.addCase(DataActions.setUnitOfMeasureData, (state, action) => {
        state.unitOfMeasureData = action.payload;
    });
    builder.addCase(DataActions.setSelectedSiteId, (state, action) => {
        state.selectedSiteId = action.payload;
    });
    builder.addCase(DataActions.setSelectedDevice, (state, action) => {
        state.selectedDevice = action.payload;
    });
    builder.addCase(DataActions.reset, () => {
        return initialState;
    });
});

export default dataReducer;
