import axios, { AxiosPromise } from 'axios';
import { IUser } from '../../@types/model/auth/user/user';
import { IUserUpsert } from '../../@types/model/auth/user/userUpsert';

export default class UserHttpService {

    public static userGetList() : AxiosPromise<Array<IUser>> {
        return axios.get(`${API_URL}/Rights/User/GetList`);
    }

    public static userUpsert = (userUpsert : IUserUpsert) : AxiosPromise<IUser | null> => {
        return axios.post(`${API_URL}/Rights/User/Upsert`, userUpsert);
    };

    public static userDelete = (userId : number) : AxiosPromise<IUser> => {
        return axios.delete(`${API_URL}/Rights/User/Delete`, {
            params: { userId },
        });
    };
}
