import { createAction } from '@reduxjs/toolkit';
import { IActivityType } from '../../@types/model/masterData/activityType/activityType';
import { IBatchStatus } from '../../@types/model/masterData/batchStatus/batchStatus';
import { IBatchType } from '../../@types/model/masterData/batchType/batchType';
import { IDepartment } from '../../@types/model/masterData/department/department';
import { IDevice } from '../../@types/model/masterData/device/device';
import { IIngredient } from '../../@types/model/masterData/ingredient/ingredient';
import { ILocation } from '../../@types/model/masterData/location/location';
import { IMeasurementType } from '../../@types/model/masterData/measurementType/measurementType';
import { IReason } from '../../@types/model/masterData/reason/reason';
import { IRecipe } from '../../@types/model/masterData/recipe/recipe';
import { ISite } from '../../@types/model/masterData/site/site';
import { IUnitOfMeasure } from '../../@types/model/masterData/unitOfMeasure/unitOfMeasure';
import { withPayloadType } from '@zz2/zz2-ui';

export default class DataActions {
    // states
    public static setIsLoading = createAction('DATA_SET_LOADING', withPayloadType<boolean>());

    // master data elements
    public static setSiteData = createAction('SITE_SET_LIST', withPayloadType<Array<ISite> | null>());
    public static setDepartmentData = createAction('DEPARTMENT_SET_LIST', withPayloadType<Array<IDepartment> | null>());
    public static setLocationData = createAction('LOCATION_SET_LIST', withPayloadType<Array<ILocation> | null>());
    public static setSelectedDevice = createAction('DEVICE_SET_SELECTED', withPayloadType<IDevice | null>());
    public static setDeviceData = createAction('DEVICE_SET_LIST', withPayloadType<Array<IDevice> | null>());
    public static setDeviceStatusData = createAction('DEVICE_SET_STATUS_LIST', withPayloadType<Array<IDevice> | null>());
    public static setActivityTypeData = createAction('ACTIVITY_TYPE_SET_LIST', withPayloadType<Array<IActivityType> | null>());
    public static setBatchTypeData = createAction('BATCH_TYPE_SET_LIST', withPayloadType<Array<IBatchType> | null>());
    public static setBatchStatusData = createAction('BATCH_STATUS_SET_LIST', withPayloadType<Array<IBatchStatus> | null>());
    public static setMeasurementTypeData = createAction('MEASUREMENT_TYPE_SET_LIST', withPayloadType<Array<IMeasurementType> | null>());
    public static setReasonData = createAction('REASON_SET_LIST', withPayloadType<Array<IReason> | null>());
    public static setRecipeData = createAction('RECIPE_SET_LIST', withPayloadType<Array<IRecipe> | null>());
    public static setUnitOfMeasureData = createAction('UNIT_OF_MEASURE_SET_LIST', withPayloadType<Array<IUnitOfMeasure> | null>());
    public static setIngredientData = createAction('INGREDIENT_SET_LIST', withPayloadType<Array<IIngredient> | null>());

    // user selected data
    public static setSelectedSiteId = createAction('SITE_SET_SELECTED', withPayloadType<number | null>());

    // data reset
    public static reset = createAction('DATA_RESET');
}