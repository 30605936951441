export const freshServiceConfig = {
    apiKey: 'IiAbY68ljMT2JXz7rDO',
    Address: 'https://zz2.freshservice.com/api/v2/tickets',
};

export enum RIGHT_GRANTS {
    View = 0,
    Add = 1,
    Edit = 2,
    Delete = 3,
    Admin = 20,
}

export const RIGHT_LEVELS : Record<string, number> = {
    'View': RIGHT_GRANTS.View,
    'Add': RIGHT_GRANTS.Add,
    'Edit': RIGHT_GRANTS.Edit,
    'Delete': RIGHT_GRANTS.Delete,
    'Admin': RIGHT_GRANTS.Admin,
};

export const RIGHT_GRANT_LEVELS : Array<{ label : string; value : number }> = [
    { label: 'View', value: RIGHT_GRANTS.View },
    { label: 'Add', value: RIGHT_GRANTS.Add },
    { label: 'Edit', value: RIGHT_GRANTS.Edit },
    { label: 'Delete', value: RIGHT_GRANTS.Delete },
    { label: 'Admin', value: RIGHT_GRANTS.Admin },
];

export const DATE_FORMAT_DEFAULT_NO_TIME = 'DD/MM/YYYY';
export const DATE_FORMAT_NO_SECONDS = 'YYYYMMDDHH:mm';
export const REPORT_CSV_DATE_FORMAT = 'DD/MM/YYYY hh:mm:ss A';

export const REPORT_CSV_HEADINGS = [
    'TRANSACTION_DATE_TIME',
    'MEASUREMENT_TYPE',
    'VALUE',
    'BATCH_NUMBER'
];

/*ZZFresh service */

export enum FreshServicePriority {
    Na = 0,
    Low,
    Medium,
    High,
    Urgent,
}

export enum FreshServiceSourceType {
    Na = 0,
    Email,
    Portal,
    Phone,
    Chat,
    FeedbackWidget,
    Yammer,
    AwasCloudwatch,
    PageDuty,
    Walkup,
    Slack,
}

export enum FreshServiceStatus {
    Na = 1,
    Open,
    Pending,
    Resolved,
    Closed,
}