import axios, { AxiosPromise } from 'axios';
import { IUserToken } from '../../@types/model/auth/userToken/userToken';
import { GoogleLoginModel } from '../../@types/model/auth/login/GoogleLoginModel';

export default class AuthHttpService {
    public static googleLogin = (googleLogin : GoogleLoginModel) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/Rights/Authorisation/LoginGoogle`, googleLogin);
    };

    public static getSession = () : AxiosPromise<IUserToken> => {
        return axios.get(`${API_URL}/Rights/Authorisation/GetSession`);
    };

    public static setEmployeeNumber = (employeeNumber : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/Rights/Authorisation/SetEmployeeNumber`, null, {
            params: {
                employeeNumber,
            },
        });
    };

    public static setPassword = (password : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/Rights/Authorisation/SetPassword`, null, {
            params: {
                password,
            },
        });
    };

    public static logInManual = (emailOrUsername : string, password : string) : AxiosPromise<IUserToken> => {
        return axios.post(`${API_URL}/Rights/Authorisation/LogIn`, {
            emailOrUsername,
            password,
        });
    };

    public static logout = () : AxiosPromise => {
        return axios.get(`${API_URL}/Rights/Authorisation/LogOut`);
    };

    public static logoutUsers = (userIds : Array<number>) : AxiosPromise => {
        return axios.post(`${API_URL}/Rights/Authorisation/LogoutUsers`, userIds);
    };

    public static requestForgottenPasswordEmail(emailOrEmpNumber : string) : AxiosPromise<boolean> {
        return axios.post(`${API_URL}/Rights/User/RequestForgottenPasswordEmail`, null, {
            params: {
                emailOrEmpNumber 
            }
        });
    }

    public static resetPassword(recoveryCode : string, email : string, newPassword : string) : AxiosPromise<boolean> {
        return axios.post(`${API_URL}/Rights/User/ResetPassword`, {
            recoveryCode,
            newPassword,
            email,
        });
    }
}
