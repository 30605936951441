import React, { useState } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import RefreshIcon from '@mui/icons-material/Refresh';
import FormControl from '@mui/material/FormControl';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import FormHelperText from '@mui/material/FormHelperText';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import AuthHttpService from '../../../service/http/authHttpService';
import * as localStorageService from '../../../service/localStorageService';
import GeneralThunks from '../../../store/general/thunks';
import { useAppDispatch } from '../../../@types/redux';

interface IEmployeeNumberDialogProps {
    open : boolean;
}

const EmployeeNumberDialog = (props : IEmployeeNumberDialogProps) : React.ReactElement => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [employeeNumber, setEmployeeNumber] = useState<string>('');
    const dispatch = useAppDispatch();

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const handleRefresh = async () : Promise<void> => {
        setIsLoading(true);
        try {
            const result = await AuthHttpService.getSession();
    
            localStorageService.setLocalStorageSession(result.data);
        } catch (e) {
            dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'An error occurred while loading session.', e }));
        } finally {
            setIsLoading(false);
        }
    };

    const handleClose = async () : Promise<void> => {
        setIsLoading(true);
        try {
            const result = await AuthHttpService.setEmployeeNumber(employeeNumber);

            localStorageService.setLocalStorageSession(result.data);

            dispatch(GeneralThunks.showSuccessSnackbar('Employee number has been set.'));
        } catch (ex) {
            dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'Invalid employee number.', e: ex }));
        } finally {
            setIsLoading(false);
        }
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const handleChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) : void => {
        setEmployeeNumber(event.currentTarget.value.toLocaleUpperCase());
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <div>
            <Dialog
                open={props.open}
                aria-labelledby='employee-dialog-title'
            >
                <DialogTitle id='employee-dialog-title'>Enter Employee Number</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Please enter your Employee Number.
                    </DialogContentText>
                    <FormControl>
                        <TextField
                            required
                            error={!employeeNumber}
                            autoFocus
                            margin='dense'
                            id='employeeNumber'
                            label='Employee Number'
                            type='text'
                            fullWidth
                            value={employeeNumber}
                            onChange={handleChange}
                        />
                        {
                            !employeeNumber &&
                            <FormHelperText error>Required</FormHelperText>
                        }
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button color='primary' disabled={isLoading} onClick={handleRefresh}>
                        {isLoading && <RefreshIcon className={'mr10'}></RefreshIcon>}
                        {isLoading && <CircularProgress color='primary' size={24} className={'mr10'}></CircularProgress>}
                        REFRESH
                    </Button>
                    <Button color='primary' disabled={isLoading} onClick={handleClose}>
                        {isLoading && <SaveIcon className={'mr10'}></SaveIcon>}
                        {isLoading && <CircularProgress color='primary' size={24} className={'mr10'}></CircularProgress>}
                        SUBMIT
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default EmployeeNumberDialog;
