import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { SnackbarProvider } from 'notistack';
import { blue, orange, red } from '@mui/material/colors';
import store from './store';
import theme from './style/materialTheme';
import './style/app.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { HashRouter } from 'react-router-dom';

if (process.env.NODE_ENV === 'production') {
    // tslint:disable-next-line:no-console
    console.log('Production?', process.env.NODE_ENV);
}

const AppRoot = () : JSX.Element => {

    const title = process.env.NODE_ENV === 'production' ? 'Compost' : 'Compost QA';
    document.title = title;

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <Provider store={store}>
                <HashRouter>
                    <SnackbarProvider maxSnack={4}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        classes={{
                            variantSuccess: theme.palette.primary.main,
                            variantError: red[600],
                            variantWarning: orange[600],
                            variantInfo: blue[600],
                        }}
                    >
                        <App />
                    </SnackbarProvider>
                </HashRouter>
            </Provider>
        </LocalizationProvider>
    );
};

const rootDomElement = document.getElementById('root');

if (rootDomElement) {
    ReactDOM.render(<AppRoot />, rootDomElement);
}
