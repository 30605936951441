import { ILatestMeasurement } from '../../@types/model/measurement/latestMeasurement';
import { IMeasurement } from '../../@types/model/measurement/measurement';
import MeasurementActions from './actions';
import { createReducer } from '@reduxjs/toolkit';

export interface IMeasurementState {
    isLoading : boolean;
    isEditDialogOpen : boolean;
    isAddMeasurementFormSubmitting : boolean;
    measurementData : Array<IMeasurement> | null;
    latestMeasurementData : Array<ILatestMeasurement>;
}

const initialState = {
    isLoading: false,
    isEditDialogOpen: false,
    isAddMeasurementFormSubmitting: false,
    measurementData: null,
    latestMeasurementData: [],
};

const measurementReducer = createReducer<IMeasurementState>(initialState, (builder) => {
    builder.addCase(MeasurementActions.setIsLoading, (state, action) => {
        state.isLoading = action.payload;
    });
    builder.addCase(MeasurementActions.setMeasurementData, (state, action) => {
        state.measurementData = action.payload;
    });
    builder.addCase(MeasurementActions.setLatestBatchMeasurementData, (state, action) => {
        state.latestMeasurementData = action.payload;
    });
    builder.addCase(MeasurementActions.setEditDialogOpenState, (state, action) => {
        state.isEditDialogOpen = action.payload;
    });
    builder.addCase(MeasurementActions.setIsAddMeasurementFormSubmitting, (state, action) => {
        state.isAddMeasurementFormSubmitting = action.payload;
    });
    builder.addCase(MeasurementActions.reset, () => {
        return initialState;
    });
});

export default measurementReducer;
