import axios, { AxiosPromise } from 'axios';
import { IRight } from '../../@types/model/auth/right/right';
import { IRightsUpsert } from '../../@types/model/auth/right/rightsUpsert';

export default class RightHttpService {

    public static rightGetList() : AxiosPromise<Array<IRight> | null> {
        return axios.get(`${API_URL}/Rights/Right/GetList`);
    }

    public static rightUpsert = (rightUpsert : IRightsUpsert) : AxiosPromise<IRight | null> => {
        return axios.post(`${API_URL}/Rights/Right/Upsert`, rightUpsert);
    };

    public static rightDelete = (rightId : number) : AxiosPromise<IRight> => {
        return axios.delete(`${API_URL}/Rights/Right/Delete`, {
            params: { rightId },
        });
    };
}
