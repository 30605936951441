import { useEffect, useMemo, useState } from 'react';
import { IUser } from '../../@types/model/auth/user/user';
import { ISite } from '../../@types/model/masterData/site/site';
import { useAppDispatch, useAppSelector } from '../../@types/redux';
import { getUserSelectedSiteLocalStorage, setUserSelectedSiteLocalStorage } from '../../service/localStorageService';
import { IOptionType, MuiAutocompleteSelect, PillButton, ProjectDialog } from '@zz2/zz2-ui';
import GeneralThunks from '../../store/general/thunks';
import { OptionType } from '../../@types/model/optionType';
import React from 'react';
import DataActions from '../../store/masterData/actions';
import MasterDataThunks from '../../store/masterData/thunks';

interface IUserSettingsProps {
    isOpen : boolean;
    setIsUserDialogOpenCallback : (state : boolean) => void;
    currentUser : IUser | undefined;
    onClose : () => void;
    signOut : () => void;
}

const UserSettings = (props : IUserSettingsProps) : React.ReactElement => {
    const dispatch = useAppDispatch();
    const isLoading : boolean = useAppSelector(x => x.masterData.isLoading);
    const sites : Array<ISite> | null = useAppSelector(x => x.masterData.siteData) ?? [];

    const selectedSite = getUserSelectedSiteLocalStorage();

    const [selectedSiteOption, setSelectedSiteOption] = useState<IOptionType | null>(null);

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {
        if (props.currentUser) {
            dispatch(MasterDataThunks.getSiteList());
        }
        loadData();
    }, [props.isOpen]);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const loadData = () : void => {

        if (selectedSite) {
            setSelectedSiteOption(selectedSite);
            dispatch(DataActions.setSelectedSiteId(selectedSite.value));
        }
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const onSiteChange = (selectedSite : IOptionType | null) : void => {
        setSelectedSiteOption(selectedSite);
    };

    const onCloseUserSettings = () : void => {
        if (!selectedSiteOption) {
            dispatch(GeneralThunks.showErrorSnackbar({ defaultMessage: 'Select a site.' }));
            return;
        }

        setUserSelectedSiteLocalStorage(selectedSiteOption);
        dispatch(DataActions.setSelectedSiteId(selectedSiteOption.value));
        props.onClose();
    };

    /*================================================================================================================
     *                                                  Memos
     * ==============================================================================================================*/

    const siteOptions = useMemo<Array<IOptionType>>(() => {
        return sites
            .filter(x => x.isActive)
            .map(OptionType.fromSite);
    }, [sites]);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    return (
        <ProjectDialog
            title={'User Settings'}
            isOpen={props.isOpen}
            fullWidth
            maxWidth={'xs'}
            isLoadingCircular={isLoading}
            onClose={onCloseUserSettings}
        >
            <div className={'fdc aic jcc m10'}>
                <MuiAutocompleteSelect
                    className={'w300 mt30'}
                    name={'site'}
                    label={'Site'}
                    options={siteOptions}
                    value={selectedSiteOption}
                    onChange={onSiteChange}
                />
                <div className={'h20'} />
                <div className={'fdr aic pb10'}>
                    <PillButton
                        className={'h35 w150'}
                        text={'LOGOUT'}
                        color={'secondary'}
                        onClick={props.signOut}
                    />
                </div>
            </div>
        </ProjectDialog>
    );
};

export default UserSettings;