import * as React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import defaultTheme from './style/materialTheme';
import { useAppDispatch, useAppSelector } from './@types/redux';
import * as localStorageService from './service/localStorageService';
import './style/app.scss';
import { initializeInterceptor } from './service/http';
import AuthActions from './store/auth/actions';
import PasswordDialog from './modules/component/dialog/PasswordDialog';
import { useEffect, useMemo, useState } from 'react';
import GeneralThunks from './store/general/thunks';
import { navReplace } from './store/nav/actions';
import SnackbarNotifier from './modules/root/SnackbarNotifier';
import { IGoogleLoginError, IGoogleLoginSuccess, Loader, Login } from '@zz2/zz2-ui';
import NavFrame from './modules/root/NavFrame';
import { VERSION } from './version';
import AuthThunks from './store/auth/thunk';
import PasswordRecovery from './modules/root/PasswordRecovery';
import PasswordRecoveryModelHelper from './modules/root/helper/passwordRecoveryModelHelper';
import { IPasswordRecoveryFormValues } from './@types/model/auth/password/passwordRecoveryFormValues';
import { IUserToken } from './@types/model/auth/userToken/userToken';
import DataActions from './store/masterData/actions';
import EmployeeNumberDialog from './modules/component/dialog/EmployeeNumberDialog';
import { GoogleLoginModel } from './@types/model/auth/login/GoogleLoginModel';

const App = () : React.ReactElement => {
    const dispatch = useAppDispatch();
    const session : IUserToken | null = useAppSelector(x => x.auth.session);
    const isLoggingIn : boolean = useAppSelector(x => x.auth.isLoggingIn);
    const isNavDrawerOpen : boolean = useAppSelector(x => x.general.isNavDrawerOpen);
    const showRecoveryScreen = location.pathname === '/reset-password';

    const [isLoading, setIsLoading] = useState<boolean>(false);

    /*================================================================================================================
     *                                                  Effects
     * ==============================================================================================================*/

    useEffect(() => {

        dispatch(AuthActions.setLoggingIn(true));

        localStorageService.onSessionChanged((user) => {
            if (user) { /* Logged In */
                initializeInterceptor(user.token, onUnauthenticated, onUnauthorized, onConnectionError);

                dispatch(AuthActions.setSession(user));
                dispatch(AuthActions.setToken(user.token));
                dispatch(AuthActions.setLoggedIn(true));
                dispatch(AuthActions.setLoggingIn(false));
            } else { /* Logged Out or Not yet logged in */
                initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
                dispatch(AuthActions.setLoggedIn(false));
                dispatch(AuthActions.setSession(null));
                dispatch(AuthActions.setLoggingIn(false));
            }
        });
        localStorageService.onUserDataChanged((userData) => {
            if (userData) {
                dispatch(DataActions.setSelectedSiteId(userData.selectedSiteId));
            } else {
                dispatch(DataActions.setSelectedSiteId(null));
            }
        });
    }, []);

    /*================================================================================================================
     *                                                  Async Methods
     * ==============================================================================================================*/

    const manualLogin = async (emailOrUsername : string, password : string) : Promise<void> => {
        setIsLoading(true);
        dispatch(AuthThunks.manualLogIn({ emailOrUsername, password }));
    };

    const onGoogleLogInSuccess = async (response : IGoogleLoginSuccess) : Promise<void> => {
        const googleLogin : GoogleLoginModel = {
            code: response.code,
        };

        const res = await dispatch(AuthThunks.logIn({ login: googleLogin })).unwrap();

        if (res) {
            navReplace('/home');
        }
    };

    const onGoogleLogInFailure = (response : IGoogleLoginError) : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: response.error ?? 'Login Error',
            e: response,
        }));
        dispatch(AuthActions.setLoggingIn(false));
    };

    const requestForgottenPassword = async (recoveryEmailOrUsername : string) : Promise<void> => {
        setIsLoading(true);
        await dispatch(AuthThunks.requestForgottenPassword({ emailOrUsername: recoveryEmailOrUsername }));
        setIsLoading(false);
    };

    /*================================================================================================================
     *                                                  Handler Methods
     * ==============================================================================================================*/

    const onUnauthenticated = (error : unknown) : void => {
        initializeInterceptor('', onUnauthenticated, onUnauthorized, onConnectionError);
        dispatch(GeneralThunks.showAuthErrorSnackbar({
            defaultMessage: 'Unauthorized.',
            e: error,
        }));

        dispatch(AuthActions.logout());
        dispatch(DataActions.reset());
        localStorageService.setLocalStorageSession(null);
        navReplace('/login');
    };

    const onUnauthorized = (error : unknown) : void => {
        dispatch(GeneralThunks.showAuthErrorSnackbar({
            defaultMessage: 'Insufficient rights.',
            e: error,
        }));
    };

    const onConnectionError = () : void => {
        dispatch(GeneralThunks.showErrorSnackbar({
            defaultMessage: 'Connection error.',
        }));
    };

    const getEnvironment = () : string => {
        if (ENV_NAME === 'production') {
            return 'ZZ2 COMPOST';
        } else if (ENV_NAME === 'qa') {
            return 'ZZ2 COMPOST QA';
        } else if (ENV_NAME === 'dev') {
            return 'ZZ2 COMPOST DEV';
        } else {
            return 'ZZ2 COMPOST ENV NOT FOUND';
        }
    };

    /*================================================================================================================
     *                                                  Form Values
     * ==============================================================================================================*/

    const getInitialPasswordFormValues = useMemo<IPasswordRecoveryFormValues>(() => {
        return PasswordRecoveryModelHelper.createFormValues();
    }, []);

    /*================================================================================================================
     *                                                  Render Methods
     * ==============================================================================================================*/

    const renderApp = useMemo<React.ReactElement>(() => {
        if (isLoggingIn) {
            return <Loader/>;
        }
        
        if (session) {
            return (
                <NavFrame />
            );
        }

        return showRecoveryScreen 
            ? <PasswordRecovery initialFormValues={getInitialPasswordFormValues}/> 
            : <Login
                env={getEnvironment()}
                version={VERSION.version}
                isLoading={isLoading}
                isLoggingIn={isLoggingIn}
                manualLogIn={manualLogin}
                requestForgottenPassword={requestForgottenPassword}
                onGoogleSignInSuccess={onGoogleLogInSuccess}
                onGoogleSignInFailure={onGoogleLogInFailure}
            />;
            
        
    }, [isLoggingIn, session, showRecoveryScreen, isNavDrawerOpen]);

    return (
        <ThemeProvider theme={defaultTheme}>
            {renderApp}
            <SnackbarNotifier />
            {
                !!session &&
                <EmployeeNumberDialog open={!session.user.employeeNumber} />
            }
            {
                !!session &&
                <PasswordDialog open={!session.user.password} />
            }
        </ThemeProvider>
    );
};

export default App;