import React, { useState } from 'react';
import { List, ListItemIcon, ListItemText, Collapse, Divider, Icon, ListItemButton } from '@mui/material';

interface ISideMenuItemProps {
    icon : React.ReactElement<unknown>;
    title : string;
    className ?: string;
    paddingLeft : number;
    boldTitle ?: boolean;
    children ?: React.ReactNode;
    disabled ?: boolean;
}

const SideMenuItem = (props : ISideMenuItemProps) : React.ReactElement => {
    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () : void => {
        setOpen(!open);
    };

    return (
        <List className={props.className}>
            <ListItemButton
                onClick={handleClick}
                className={`${open ? 'bcg0' : 'bcw'} sideMenuButton`}
                key={props.title}
                disabled={props.disabled}
            >
                <ListItemIcon>
                    {props.icon}
                </ListItemIcon>
                <ListItemText
                    disableTypography
                    className={`${props.boldTitle ? 'fw550' : ''}`}
                    primary={props.title}
                    title={props.title}
                />
                <Icon>{open ? 'expand_less' : 'expand_more'}</Icon>
            </ListItemButton>
            {!!open && <Divider />}
            <Collapse
                style={{ paddingLeft: props.paddingLeft }}
                className={props.className}
                in={open}
                timeout='auto'
                unmountOnExit >
                <List disablePadding>
                    {props.children}
                </List>
            </Collapse>
        </List>
    );
};

export default SideMenuItem;
